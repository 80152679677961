import React from 'react';
import {Routes, Route} from 'react-router-dom'
import  { useDispatch } from 'react-redux';
import { fetchData } from '../../Store/dataSlice.js';
import Main from '../main/main.jsx';
import Record from '../record/record';
import 'bulma/css/bulma.min.css';


function App() {  
  const dispatch = useDispatch();  
  dispatch(fetchData());  
  return (    
    <Routes>
       <Route exact path="/" element = { <Main/>} />
       <Route exact path="/record" element = { <Record/>} />
    </Routes>           
  );
}

export default App;
