import React from "react";
import {  HashLink  as Link } from "react-router-hash-link";
import {  useSelector } from "react-redux";

function MainSection() {
  const data = useSelector(state => state.data);
  
  if (data.state === 'loading') {
    return (
    <section className="hero main_hero is-fullheight">
      <div className="hero-body">
      </div>
    </section>
    )
  }  


  return (    
    <section className="hero main_hero is-fullheight">
      <div className="hero-body">
        <div className="container has-text-centered">
        <div className="image-caption">          
          <div className="category">{data.settings.phone}</div>
          <h2 className="post-title">{data.settings.title}</h2>
          <div className="post-readmore">
            <Link to={`#price`}>Цены</Link>
            <Link to={`#contacts`}>Контакты</Link> 
          </div>
        </div>
        </div>
      </div>
    </section>
  );
}

  export default MainSection;