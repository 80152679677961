import React from "react";
import {  useSelector } from "react-redux";

function PageFooter() {
  const data = useSelector(state => state.data);

  return (    
    <footer className="hero" id='contacts'>
      <div className="hero-body">
        <div className="has-text-centered">
              <div className="content">
                <h6 className="footer_title title is-6">Адрес</h6>
                <p className="is-marginless">{data.settings.address}</p>                
                <div>
                  <i className="fa fa-phone"></i> &nbsp; <span>Телефон:</span> &nbsp;
                  <a href="tel:+71231234567">{data.settings.phone}</a>
                </div>              
			        </div>          
            </div>
          </div>
        <div className="hero-foot">
        <div className="container has-text-centered">
          <p className="footer-text">&copy; 2023<br /></p>
        </div>
      </div>
    </footer>
  );
}

  export default PageFooter;