import React from "react";
import MainSection from "../mainSection/MainSection.jsx";
import PageFooter from "../pageFooter/PageFooter.jsx";
import PriceSection from "../priceSection/priceSection.jsx";
import '../../../public/css/styles.css';

function Main() {
  return (    
    <React.Fragment>
      <MainSection/>
      <PriceSection/>      
      <PageFooter/>
    </React.Fragment>
  )  ;
}


export default Main;