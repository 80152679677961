import React from "react";


function Record() {
  
  return (    
    <section className="hero main_hero is-fullheight">
      <div className="hero-body">
        <div className="container has-text-centered">
        <div className="image-caption">          

        </div>
        </div>
      </div>
    </section>
  );
}

export default Record;
