import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL } from './../consts.js';

export const fetchData = createAsyncThunk(
    'data/fetchData',    
    async function(a, {rejectWithValue}) {
        try {
              const response = await fetch(API_URL + `/fetchdata`, {                
                method: 'POST',        
                credentials: 'omit',
                headers: {                                   
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',                    
                },   
                body: JSON.stringify({request_type: 6,}),
            });

            if (!response.ok) {
                throw new Error('Server Error!');
            }
    
            const responseData = await response.json();  
            const respObj = JSON.parse(responseData);          
            return respObj;
            
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);


const setError = (state, action) => {    
    state.status = 'rejected';
    state.error = action.payload;
}

const dataSlice = createSlice({
  name: 'prices',
    initialState: {    
        settings: {                  
          title: "",
          phone: "",
          address: "",
          simple: 1,
        },
        pricelists: [],
        status: "",        
    }, 
  extraReducers: {
        [fetchData.pending]: (state) => {
            state.status = 'loading';
            state.error = null;
        }, 
        [fetchData.fulfilled]: (state, action) => {
            state.status = 'resolved';        
            state.pricelists = action.payload.prices;
            state.settings = action.payload.settings;                
        },   
        [fetchData.rejected]: setError,
  }
})

export default dataSlice.reducer;
