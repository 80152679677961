import React from "react";
import PriceTableRow from "../priceTableRow/PriceTableRow.jsx";

function PriceCard(props) {
  const {data} = props;
  return (    
    <div className={(props.max === 3)? "column is-one-third":"column is-one-half"}>
      <div className="card">
        <div className="card-content">
          <div className="content">
            <p className="title is-5">{data.Title}</p>
            <table className="table">
              <thead>
                  <tr>
                    <th><strong>Услуга</strong></th>
                    <th><strong>Цена</strong></th>
                  </tr>								
              </thead>
              <tbody>	                  
                {data.Items.map((priceData) => (<PriceTableRow key={priceData.id} data={priceData} />))}
              </tbody>
            </table>
          </div>
        </div>
      </div>						
  </div>
  );
  }

export default PriceCard;