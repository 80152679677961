import React from "react";
import {  useSelector } from "react-redux";
import PriceCard from "../priceCard/priceCard.jsx";

function PriceSection() {  
  const data = useSelector(state => state.data);

  if (data.state === 'loading') {
  return (      
    <React.Fragment>
      <section className="hero" id="price">
        <div className="hero-body">
        </div>		
    </section>	
    </React.Fragment>
  );
  }  

  const prices = data.pricelists;
  return (      
    <React.Fragment>
      <section className="hero" id="price">
        <div className="hero-body">
          <div className="container  ">
            <p className="title">Цены</p>
            <div className="columns ">		
              {prices.map((pricelist) => (<PriceCard key={pricelist.Id} data={pricelist} max={4}/>))}       
            </div>
          </div>    
        </div>		
    </section>	
    </React.Fragment>
  );
}

  export default PriceSection;