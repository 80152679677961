import React from "react";


function PriceTableRow(props) {
  const {data} = props;
  return (    
    <tr>
      <td>{data.servicename}</td>
      <td>{data.price}</td>
    </tr>
  );
}

  export default PriceTableRow;